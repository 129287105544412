import joanOroConfig from './config.joanoro';
import castellarnauConfig from './config.castellarnau';
import cavallbernatConfig from './config.cavallbernat';

function getConfigByDomain()
{
    const domain = window.location.hostname;
    let config 

    switch (domain) {
        case 'matricula.institutjoanoro.cat':
            config = joanOroConfig
            config.develop = false;
            break;
        case 'matricula.institutcastellarnau.cat':
            config = castellarnauConfig
            config.develop = false;
            break;
        case 'matricula.inscavallbernat.cat':
            config = cavallbernatConfig
            config.develop = false;
            break;
        default:
            config = castellarnauConfig
            //config = cavallbernatConfig
            //config = joanOroConfig
            config.develop = true;
            break;
    }

    return config;
}


export default getConfigByDomain()