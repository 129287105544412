import DatePicker, { registerLocale }  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import ca from 'date-fns/locale/ca';
import config from '../../config';
//const families  = require('../../data/' + config.families)

registerLocale('ca', ca)

export  const DateSelector = (props) => {

   return (
     <DatePicker
       locale={ca}
       selected={props.selected}
       onChange={(d:Date) => props.onChange(d)}
       showMonthDropdown
       showYearDropdown
       dropdownMode="select"
       minDate={props.minDate}
       maxDate={props.maxDate}
       dateFormat="P"
       withPortal
     />
   );
};

export const FormSelectYesNo = (props) => {

return (<div className={ 'col ' + props.col}>

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}{ 
            props.obligatori && (<span className="t-Form-required"> <span className="a-Icon">*</span> </span>)
          }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">

            <div className="col  ">
              <input type="radio" id={props.id + '_SI'} required={props.obligatori} defaultChecked={props.value === "1" ? true : false}  name={props.name} value="1" onClick={props.handleChange} />
              <label htmlFor={props.id + '_SI'} id={props.id + '_LABEL_SI'} className="t-Form-label">Sí</label>
            </div>

            <div className="col ">
              <input type="radio" id={props.id + '_NO'} required={props.obligatori} defaultChecked={props.value === "0" ? true : false}  name={props.name} value="0" onClick={props.handleChange} />
              <label htmlFor={props.id + '_NO'} id={props.id + '_LABEL_NO'} className="t-Form-label">No</label>
            </div>
          </div>

          <span id="{props.id}_ERROR" className="a-Form-error">{props.error}</span>


        </div>

      </div>

    </div>)
}

export const FormInputText = (props) => {


return (
    <div className={ 'col ' + props.col}>
      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}:{ props.obligatori ? (<sup>*</sup>) : "" }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">
            
            <input type="text" id={props.id} name={props.name} className="text_field"
            required={props.obligatori} value={props.value} size={props.size} maxLength={props.maxLength} onBlur={ (t) => props.onBlur(t.target.value) }  onChange={ (t) => props.onChange(t.target.value) } />

          </div>

          { props.error && (<span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>) }

        </div>
      </div>
    </div>
  )
}


export const FormSelectRadio = (props) => {

    const elements = props.elements

    let disabled = false 
      //console.log('handle 0',props,disabled)


    if(props.disabled) { disabled = true }

      //console.log('handle 11',disabled)

    const handle = (e,disabled) => {

      //console.log('handle 0',e,disabled)
      if(!disabled)
      {
      //console.log('handle 1',e,disabled)
       props.handleChange(e)  
      }

    }
  
    return (<div className="col col-12 ">
  
        <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">
  
          <div className="t-Form-labelContainer">
            <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
          </div>
  
          <div className="t-Form-inputContainer">
  
            <div className="t-Form-itemWrapper">
  
              { elements.map( (element,index) => {


                  let checked = props.value === element.id ? true : false

                  if(props.enabledelements)
                  {
                    disabled = props.enabledelements.includes(element.id) ? false : true 
                    if(disabled && props.value === element.id ){ handle(element.id === "T" ? "M" :"T") }
                  }


                  if (props.name === "curs") {

                    //console.log('CURS',element.id," == ",props.cursObligatori,disabled)

                    if (props.cursObligatori) {
                      // si hi ha marcat un curs obligatori deshabilitem tots els que no ho siguin.
                      if (element.id !== props.cursObligatori) { disabled = true }

                      //TODO potser cal controlar si està deshabilitat, però en cas contrari no es podria matricular de res, i si fos per fora de termini ja no arriba aquí
                      // habilitem el curs obgligatori i el possible pla (A)ntic si existeix
                      if (element.id === props.cursObligatori || element.id === props.cursObligatori + "A" ) { disabled = false }
                    }
                  }


                return (<div className={'col ' + props.col} key={index}>
                  <input type="radio" id={element.id} name={props.name} disabled={disabled} checked={checked} value={element.id} onClick={ (e) => handle(element.id,e.target.disabled) } />
                  <label htmlFor={element.id} id={element.id+ '_LABEL'} className="t-Form-label">{element.value}</label>
                </div>) } )}
                 

  
            </div>
  
            <span id="{props.id}_ERROR" className="a-Form-error"></span>
  
          </div>
  
        </div>
  
      </div>)
  }

export const FormTextArea = (props) => {

return (
    <div className={ 'col ' + props.col}>
      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}:{ props.obligatori ? (<sup>*</sup>) : "" }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">
            
            <textarea id={props.id} name={props.name} className="textarea" wrap="virtual"
            required={props.obligatori} defaultValue={props.value} rows={props.rows} cols={props.cols} maxLength={props.maxLength} onChange={ (t) => props.onChange(t.target.value) } />

          </div>

          { props.error && (<span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>) }

        </div>
      </div>
    </div>
  )
}

const isInDate = (dates) =>
{
  // algun institut no vol limitació de dates només a nivell informatiu per tant tornem sempre false ja que el retorn és per deshabilitar
  if(config.datesNoBloquejenInscripcio) return false

  let ara = new Date().getTime()

  let dataIni = new Date(dates.dataInici)
  let dataFi = new Date(dates.dataFi)

  //console.log("isInDate: ", dataIni.getTime() + " >= "+ ara + " && "+ dataFi.getTime() + " <= " + ara)

  return dataIni.getTime() <= ara && dataFi.getTime()+86400000 >= ara ? false : true
}


const DatesSmall = (props) => {
  const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}/ 

  const formatDataInici = dateTimePattern.test(props.dates.dataInici) ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
  const formatDataFi = dateTimePattern.test(props.dates.dataFi) ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"

  return (<small className="datesMatricula">(del {format(new Date(props.dates.dataInici),formatDataInici)} al {format(new Date(props.dates.dataFi),formatDataFi)})</small>)
}


export const FormSelectCicle = (props) => {

  //DEBUG console.log("props.families",props.families)


   let col = props.nouAlumne ?  "col-6" : "col-6"

  let idx = props.nouAlumne ? 0 : 1

  let dates = []
  let disabled = []

  //DEBUG console.log("props.families => ",props.families)

  Object.keys(props.families).forEach(

    (k) => {
      dates[k] = props.families[k].datesMatricula[idx]
      switch(k)
      {
      case "CFGM":
      case "CFGS":
                    // si no compleix criteris (npreinscipcio i adjunt de titol) deshabilitem matricula de cicles 
                    disabled[k] = props.potMatricularCicles ? isInDate(dates[k]) : true
                    break;
      default:
                    disabled[k] = isInDate(dates[k])
      }
    }

  )

  if(props.families["CFGM"])
  {
  dates["CFGM"] = props.families["CFGM"].datesMatricula[idx]
  disabled["CFGM"] = props.potMatricularCicles ?  isInDate(dates["CFGM"]) : true
  }

  if(props.families["CFGS"])
  {
  dates["CFGS"] = props.families["CFGS"].datesMatricula[idx]
  // si no compleix criteris (npreinscipcio i adjunt de titol) deshabilitem matricula de cicles 
  disabled["CFGS"] = props.potMatricularCicles ?  isInDate(dates["CFGS"]) : true
  }

  // TEMPORAL NOMÉS PER A QUE ELS ALUMNES ANTICS PUGUIN ACABAR DE MATRICULAR-SE
  /*
  if(!props.nouAlumne)
  {
    disabled["CFGM"] = false
    disabled["CFGS"] = false
  }
  */



let label = []
label['CFGM'] = "CFGM - Cicle Formatiu de Grau Mitjà"
label['CFGS'] = "CFGS - Cicle Formatiu de Grau Superior"
label['ESO'] = "ESO - Educació Secundària Obligatòria"
label['BATX'] = "Batxillerat"
label['CE'] = "Cursos Especialització"
label['PFI'] = "Programes de Formació i Inserció"
label['FPB'] = "Formació Professional Bàsica"
label['IFE'] = "Itineraris Formatius Específics"


  return (<div className="col col-12 ">

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">


          {/* TODO: caldria reordenar families dins JSON per seguir ordre de labels i posar label a cada camp per mostrar text */

           Object.keys(label).map( (f) => 
            props.families[f] && (
                        <div className={'col ' + col}>
                          <input type="radio" id={props.id + '_' + f} name="CICLE" value={f} disabled={disabled[f]} checked={props.cicle === f ? true : false} onClick={props.handleChange} />
                          <label htmlFor={props.id + '_' + f} id={props.id + '_LABEL'} className="t-Form-label">{f} - { props.families[f].label ? props.families[f].label : label[f]}&nbsp;<DatesSmall dates={dates[f]}/></label>
                        </div>)

          )}


{/* props.families["CFGM"] && (
            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_CFGM'} name="CICLE" value="CFGM" disabled={disabled["CFGM"]} checked={props.cicle === "CFGM" ? true : false} onClick={props.handleChange} />
              <label htmlFor={props.id + '_CFGM'} id={props.id + '_LABEL'} className="t-Form-label">CFGM - Cicle Formatiu de Grau Mitjà&nbsp;<DatesSmall dates={dates["CFGM"]}/></label>
            </div>
)}

{ props.families["CFGS"] && (
            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_CFGS'} name="CICLE" value="CFGS" disabled={disabled["CFGS"]} checked={props.cicle === "CFGS" ? true : false} onClick={props.handleChange} />
              <label htmlFor={props.id + '_CFGS'} id={props.id + '_LABEL'} className="t-Form-label">CFGS - Cicle Formatiu de Grau Superior&nbsp;<DatesSmall dates={dates["CFGS"]} /></label>
            </div>
)}

{ props.families["ESO"] && (
            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_ESO'} name="CICLE" value="ESO" disabled={disabled["ESO"]} checked={props.cicle === "ESO" ? true : false} onClick={props.handleChange} />
              <label htmlFor={props.id + '_ESO'} id={props.id + '_LABEL'} className="t-Form-label">ESO - Educació Secundària Obligatòria&nbsp;<DatesSmall dates={dates["ESO"]} /></label>
            </div>
)}

{ props.families["BATX"] && (
            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_BATX'} name="CICLE" value="BATX" disabled={disabled["BATX"]} checked={props.cicle === "BATX" ? true : false} onClick={props.handleChange} />
              <label htmlFor={props.id + '_BATX'} id={props.id + '_LABEL'} className="t-Form-label">Batxillerat&nbsp;<DatesSmall dates={dates["BATX"]} /></label>
            </div>
)}

{ props.families["CE"] && (props.nouAlumne && (
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_CE'} name="CICLE" value="CE" disabled={disabled["CE"]}checked={props.cicle === "CE" ? true : false}  onClick={props.handleChange} />
                <label htmlFor={props.id + '_CE'} id={props.id + '_LABEL'} className="t-Form-label">Cursos Especialització&nbsp;<DatesSmall dates={dates["CE"]} /></label>
              </div>))
}

{ props.families["PFI"] && ( props.nouAlumne && (
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_PFI'} name="CICLE" value="PFI" disabled={disabled["PFI"]} checked={props.cicle === "PFI" ? true : false}   onClick={props.handleChange} />
                <label htmlFor={props.id + '_PFI'} id={props.id + '_LABEL'} className="t-Form-label">Programes de Formació i Inserció&nbsp;<DatesSmall dates={dates["PFI"]} /></label>
              </div>))
}

{ props.families["FPB"] && (
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_FPB'} name="CICLE" value="FPB" disabled={disabled["FPB"]} checked={props.cicle === "FPB" ? true : false}   onClick={props.handleChange} />
                <label htmlFor={props.id + '_FPB'} id={props.id + '_LABEL'} className="t-Form-label">Formació Professional Bàsica&nbsp;<DatesSmall dates={dates["FPB"]} /></label>
              </div>)
}

{ props.families["IFE"] && ( 
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_IFE'} name="CICLE" value="IFE" disabled={disabled["IFE"]} checked={props.cicle === "IFE" ? true : false}   onClick={props.handleChange} />
                <label htmlFor={props.id + '_IFE'} id={props.id + '_LABEL'} className="t-Form-label">Itineraris Formatius Específics&nbsp;<DatesSmall dates={dates["IFE"]} /></label>
              </div>)
*/}



          </div>

          <span id={props.id + '_ERROR'} className="a-Form-error"></span>


        </div>

      </div>

    </div>)
}

export const FormSelectNouAlumne = (props) => {

  return (<div className="col col-12 ">

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label htmlFor={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
        { props.labelSub && (<><br/><i><small>{props.labelSub}</small></i><br/><br/></>) }
        </div>

        <div>
        </div>

        <div className="t-Form-inputContainer col col-6">

          <div className="t-Form-itemWrapper">

            <div className="col col-2 ">
              <input type="radio" id={props.id + '_SI'} name="nouAlumne" checked={props.nouAlumne === true} value="1" onClick={props.handleChange} />
              <label htmlFor={props.id + '_SI'} id={props.id + '_LABEL_SI'} className="t-Form-label">Sí</label>
            </div>

            <div className="col col-2 ">
              <input type="radio" id={props.id + '_NO'} name="nouAlumne" checked={props.nouAlumne === false}  value="0" onClick={props.handleChange} />
              <label htmlFor={props.id + '_NO'} id={props.id + '_LABEL_NO'} className="t-Form-label">No</label>
            </div>
          </div>

          <span id="{props.id}_ERROR" className="a-Form-error"></span>


        </div>

      </div>

    </div>)
}